import React from "react";
import { useSelector } from "react-redux";
import { EwmmsApp, EwmmsTypes } from "@walmart/wmms-helpcenter-library";
if (process.env.NODE_ENV !== 'test') {
  require('@walmart/wmms-helpcenter-library/dist/index.css');
}
/*
*   QnA component
*/
const QnAPortal = () => {

  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);

  const userDetail = {
    userId: userDetails?.userId || "",
    userName: userDetails?.firstName || "",
    state: "TX",
    businessUnit: EwmmsTypes?.WMMSBusinessUnit?.ANY,
  };

  return (
    <div>
          <EwmmsApp {...userDetail} />
    </div>
  );
};

export default QnAPortal;
